<template>
  <v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" fab :class="$vuetify.breakpoint.xs ? '' : 'mr-6'" :small="$vuetify.breakpoint.mdAndUp" :x-small="$vuetify.breakpoint.xsOnly">
        <v-icon color="primary">mdi-pencil</v-icon>
      </v-btn>
    </template>
    <v-card shaped class="pa-3">
      <v-toolbar elevation="5" class="row no-gutters justify-center" dense color="primary">
        <v-toolbar-title>Редактировать</v-toolbar-title>
      </v-toolbar>
      <v-row class="ma-3">
        <v-col cols="12">
          <v-text-field rounded disabled outlined dense hide-details v-model="item.app" label="Устройство"/>
        </v-col>
        <v-col cols="12">
          <v-text-field rounded disabled outlined dense hide-details v-model="item.os" label="О.С."/>
        </v-col>
        <v-col cols="12">
          <v-text-field rounded outlined dense hide-details v-model="item.version" label="Версия"/>
        </v-col>
      </v-row>
      <v-row class="justify-space-around pb-3">
        <v-btn rounded outlined elevation="3" color="error" @click="dialog = false">Отменить</v-btn>
        <v-btn rounded outlined elevation="3" color="primary" @click="save()">Сохранить</v-btn>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import {uuid} from "uuidv4";
import axios from "@/services/axios";

export default {
  props: {
    version: Object
  },
  data() {
    return {
      item: {
        id: uuid.Nil,
        app: "",
        os: "",
        version: ""
      },
      dialog: false
    }
  },
  mounted() {
    this.item.id = this.version.id;
    this.item.app = this.version.app;
    this.item.os = this.version.os;
    this.item.version = this.version.version;
  },
  methods: {
    save() {
      axios.send({
        url: "/versions/",
        method: "PUT",
        data: this.item
      }).then(() => {
        this.$router.go();
      })
    }
  }
}
</script>
