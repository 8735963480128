<template>
  <v-card class="my-5">
    <v-toolbar color="green darken-2" dark>
      <v-toolbar-title class="row justify-center">Версия</v-toolbar-title>
    </v-toolbar>
    <v-row class="mx-5 my-1">
      <v-col cols="10"></v-col>
      <v-col cols="2" class="row no-gutters justify-center align-center d-flex">
        <newversion></newversion>
      </v-col>
    </v-row>
    <v-row no-gutters v-for="version in versions" :key="version.id" class="ma-1 py-1 grey lighten-4">
      <v-col cols="3" class="row no-gutters justify-center d-flex align-center">
        <span class="text-body-2">{{ version.app }}</span>
      </v-col>
      <v-col cols="3" class="row no-gutters justify-center d-flex align-center">
        <span class="text-body-2">{{ version.os }}</span>
      </v-col>
      <v-col cols="3" class="row no-gutters justify-center d-flex align-center">
        <span class="text-body-2">{{ version.version }}</span>
      </v-col>
      <v-col cols="3" class="row no-gutters justify-space-around d-flex align-center">
        <editversion :version="version"></editversion>
        <v-btn fab :class="$vuetify.breakpoint.xs ? '' : 'mr-6'" :small="$vuetify.breakpoint.mdAndUp" :x-small="$vuetify.breakpoint.xsOnly" @click="remove(version.id)">
          <v-icon color="error">mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import axios from "@/services/axios";
import {uuid} from "uuidv4";
import newversion from "./new.vue"
import editversion from "./edit.vue"

export default {
  components: { newversion, editversion },
  data: () => ({
    item: {
      id: uuid.Nil,
      app: "",
      os: "",
      version: ""
    },
    defaultItem: {
      id: uuid.Nil,
      app: "",
      os: "",
      version: ""
    },
    versions: []
  }),

  created() {
    this.fetch();
  },

  methods: {
    fetch() {
      axios.send({
        url: "/versions",
        method: "GET"
      }).then(resp => {
        this.versions = resp.data;
      })
    },
    remove(id) {
      this.$confirm(
          "Удалить",
          "Внимание",
          "question"
      ).then(() => {
        axios.send({
          url: "/versions/" + id,
          method: "DELETE",
        }).then(() => {
          this.fetch();
        })
      });
    }
  }
}
</script>
